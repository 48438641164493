// eslint-disable-next-line no-restricted-imports
import { Autocomplete as AutocompleteBase } from '@mui/material';
import { lodash } from 'helpers';
import { useTranslation } from 'libs/i18n';

import { Icon } from '../Icon';
import { Input } from '../Input';
import { Typography } from '../Typography';

import { AutocompleteStyled } from './Autocomplete.styles';
import { AutocompleteProps } from './types';

/**
 * Autocomplete component.
 */
export const Autocomplete = <
  Value extends object,
  Multiple extends boolean | undefined = false
>({
  fullWidth = true,
  value,
  label,
  placeholder,
  loading,
  loadingText,
  error,
  helperText,
  options,
  optionLabelKey,
  optionValueKey,
  className,
  disabled,
  hideClearIcon,
  variant,
  multiple,
  readOnly,
  helperTextAbsolute,
  showCopyButton,
  onChange,
  getOptionDisabled,
  getOptionLabel,
}: AutocompleteProps<Value, Multiple>) => {
  const { t } = useTranslation();

  const getMultipleLabels = (value: Value[]) =>
    value?.map((option) => option[optionLabelKey] as string).join(', ');

  return (
    <AutocompleteStyled disabled={disabled} readOnly={readOnly}>
      <AutocompleteBase
        className={className}
        clearIcon={hideClearIcon ? null : undefined}
        disableCloseOnSelect={multiple}
        disabled={disabled}
        filterOptions={(options, state) =>
          lodash.searchBy(options, state.inputValue, (option) =>
            String(option[optionLabelKey])
          )
        }
        fullWidth={fullWidth}
        getOptionDisabled={getOptionDisabled}
        getOptionLabel={getOptionLabel ?? ((v) => v[optionLabelKey] as string)}
        isOptionEqualToValue={(v, option) =>
          v?.[optionValueKey] === option?.[optionValueKey]
        }
        loading={loading}
        loadingText={loadingText || `${t('common.loading')}...`}
        multiple={multiple}
        noOptionsText={t('components.autocomplete.noOptionsText')}
        options={options}
        popupIcon={readOnly ? null : <Icon.ArrowDown size={24} />}
        readOnly={readOnly}
        renderInput={(props) => {
          const { InputLabelProps, ...rest } = props;
          rest.inputProps.readOnly = readOnly || variant === 'select';
          const inputValue = multiple
            ? getMultipleLabels(value as Value[])
            : props.inputProps.value;
          return (
            <Input
              showCopyButton={showCopyButton}
              {...rest}
              error={error}
              helperText={helperText}
              helperTextAbsolute={helperTextAbsolute}
              label={label}
              placeholder={
                value instanceof Array && value.length > 0
                  ? undefined
                  : placeholder
              }
              readOnly={readOnly}
              value={inputValue}
            />
          );
        }}
        renderTags={(value) => {
          const labels = getMultipleLabels(value);

          return (
            <Typography className="autocomplete-tag" title={labels}>
              {labels}
            </Typography>
          );
        }}
        slotProps={{ popupIndicator: { disableRipple: true } }}
        value={value}
        onChange={onChange}
      />
    </AutocompleteStyled>
  );
};
