import { useEffect, useRef, useState } from 'react';

import { lodash } from 'helpers';
import { Input, InputProps } from 'libs/ui';

import { FieldType } from '../types';
import { useField } from '../useField';

export interface TextFieldProps
  extends FieldType,
    Omit<InputProps, 'onChange' | 'onChangeText' | 'value'> {
  checkValue?: (v: string) => boolean;
}

/**
 * Component that abstracts and simplifies the usage of Form Field component
 * for managing form input fields. It renders an Input component with the provided props.
 */
export const TextField = ({
  name,
  allowSubmit,
  readOnly,
  checkValue,
  ...props
}: TextFieldProps) => {
  const { field, helpers, inputProps, meta } = useField<string | undefined>(
    name
  );
  const [value, setValue] = useState(field.value);

  const bounce = useRef(lodash.debounce(helpers.setValue, 200)).current;

  useEffect(() => {
    if (value === undefined) {
      setValue(field.value ?? '');
    }
  }, [field.value, value]);

  useEffect(() => {
    if (readOnly) {
      setValue(field.value ?? '');
    }
  }, [field.value, readOnly]);

  return (
    <Input
      {...props}
      value={value}
      onBlur={field.onBlur(name)}
      onChange={() => {
        if (meta.isError) {
          helpers.setError('');
        }
      }}
      onChangeText={(v) => {
        if (!checkValue || checkValue(v)) {
          setValue(v);
          bounce(v);
        }
      }}
      {...inputProps}
      readOnly={readOnly || inputProps.readOnly}
    />
  );
};
