import {
  useDeleteLegalClientHead,
  useLegalClient,
  useLegalClientHead as useLegalClientHeadApi,
  useLegalClientHeadArchive,
  useLegalClientHeadDearchive,
  useLegalClientHeadSaveEmail,
  useLegalClientHeadSendCorporateAccountVerificationEmail,
} from 'api/requests';
import { useCountries } from 'api/requests/country';
import { PermissionType } from 'api/types/entity';
import { NavigationBreadcrumbsTitles } from 'components';
import { parseFullName } from 'helpers';
import { useServerErrorNotify } from 'hooks';
import { FormSubmit } from 'libs/form/Form';
import { useTranslation } from 'libs/i18n';
import { useNavigate, RoutesId, ROUTES } from 'libs/navigation';
import { useNotify } from 'libs/notify';
import { usePermissions } from 'libs/permissions';

import { LegalClientHeadFormType } from '../forms/legalClientHeadForm';
import { getLegalClientHeadInitialValue } from '../helpers';

import { useHeadPageTitle } from './useHeadPageTitle';
import { useLockLegalClientHead } from './useLockLegalClientHead';
import { useUpdateLegalClientHead } from './useUpdateLegalClientHead';

type NavigateParams = {
  headId: string;
  legalClientId: string;
};

export const useLegalClientHead = () => {
  const { t } = useTranslation();
  const notify = useNotify();
  const { checkPermissions } = usePermissions();

  const { navigate, params } = useNavigate<NavigateParams>();

  const { loading, legalClientHead, error } = useLegalClientHeadApi(
    params.headId!
  );

  const { updateLegalClientHead, loading: submitLoading } =
    useUpdateLegalClientHead();

  const { countries, loading: countriesLoading } = useCountries();

  const { legalClient } = useLegalClient(params.legalClientId!);

  const { lockClientIfPossible, unlockClient } = useLockLegalClientHead(
    legalClient,
    legalClientHead
  );

  const {
    deleteLegalClientHead,
    loading: deleteLoading,
    error: deleteError,
  } = useDeleteLegalClientHead();

  const {
    data: { resendTime: resendVerificationEmailTime },
    sendVerificationEmail,
    error: verificationEmailError,
  } = useLegalClientHeadSendCorporateAccountVerificationEmail();

  const {
    loading: changeEmailLoading,
    saveEmail,
    error: changeEmailError,
  } = useLegalClientHeadSaveEmail();

  const {
    loading: archiveLoading,
    archive,
    error: archiveError,
  } = useLegalClientHeadArchive();
  const {
    loading: dearchiveLoading,
    dearchive,
    error: dearchiveError,
  } = useLegalClientHeadDearchive();

  const initialValues = legalClientHead
    ? getLegalClientHeadInitialValue(legalClientHead, t)
    : undefined;

  const breadcrumbsTitles: NavigationBreadcrumbsTitles = {
    [RoutesId.legalClientHead]: legalClientHead
      ? parseFullName(legalClientHead)
      : '',
    [RoutesId.legalClient]: legalClient?.fullName ?? '',
  };

  const onSubmit: FormSubmit<LegalClientHeadFormType> = async (
    values: LegalClientHeadFormType,
    helpers
  ) => {
    if (await updateLegalClientHead(legalClientHead!, values)) {
      helpers.setReadOnly(true);
    }
  };

  const onShowVersionHistory =
    legalClient &&
    legalClientHead?.versionNumber &&
    legalClientHead?.versionNumber > 1
      ? () => {
          navigate(
            ROUTES.clients.legalClients.legalClient.legalClientVersion
              .legalClientHeadVersion.fullPath,
            {
              legalClientId: legalClient.id,
              headId: params.headId!,
              versionId: legalClientHead.versionId!,
            }
          );
        }
      : undefined;

  const onDelete = checkPermissions([PermissionType.LegalClientHeadDeleteOne])
    ? async (reason: string) => {
        if (
          await deleteLegalClientHead({
            id: params.headId!,
            reason,
            versionNumber: legalClient?.versionNumber!,
          })
        ) {
          notify.info(t('client.legal.legalClientHead.successDeleteMessage'));
          navigate(
            ROUTES.clients.legalClients.legalClient.fullPath,
            {
              legalClientId: params.legalClientId!,
            },
            { replace: true }
          );
        }
      }
    : undefined;

  const onSendVerificationEmail = !legalClientHead?.isActive
    ? async () => {
        await sendVerificationEmail(params.headId!);
        notify.info(t('common.emailSent'));
      }
    : undefined;

  const onChangeEmail = !legalClientHead?.isActive
    ? async (email: string, reason: string) => {
        if (
          await saveEmail(params.headId!, {
            email,
            reason,
            versionNumber: legalClientHead?.versionNumber!,
          })
        ) {
          notify.info(t('common.emailSent'));
          return true;
        }
        return false;
      }
    : undefined;

  const onArchive = !legalClientHead?.archived
    ? async (reason: string) => {
        if (
          await archive(params.headId!, {
            reason,
            versionNumber: legalClientHead?.versionNumber!,
          })
        ) {
          notify.info(t('client.legal.legalClientHead.archivedMessage'));
          return true;
        }
        return false;
      }
    : undefined;

  const onDearchive = legalClientHead?.archived
    ? async (reason: string) => {
        if (
          await dearchive(params.headId!, {
            reason,
            versionNumber: legalClientHead?.versionNumber!,
          })
        ) {
          notify.info(t('client.legal.legalClientHead.dearchivedMessage'));
          return true;
        }
        return false;
      }
    : undefined;

  useHeadPageTitle(legalClient, legalClientHead);

  useServerErrorNotify(
    verificationEmailError ?? deleteError ?? archiveError ?? dearchiveError
  );

  return {
    legalClientHead,
    loading: !legalClientHead && loading,
    countries: countries ?? [],
    countriesLoading,
    error,
    deleteLoading,
    submitLoading,
    initialValues,
    breadcrumbsTitles,
    legalClient,
    resendVerificationEmailTime,
    changeEmailLoading,
    changeEmailError,
    archiveLoading,
    dearchiveLoading,
    onChangeEmail,
    onSubmit,
    lockClientIfPossible,
    unlockClient,
    onShowVersionHistory,
    onDelete,
    onSendVerificationEmail,
    onArchive,
    onDearchive,
  };
};
