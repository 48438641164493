import { FC, useRef } from 'react';

import { ServerError } from 'api/types/error';
import { SendCodeTimer } from 'components';
import { parseFullName } from 'helpers';
import { useFormErrorFocus } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { useDialog } from 'libs/ui/Dialog/useDialog';
import {
  DialogChangeEmail,
  DialogDeleteAndReasonChange,
  DialogReasonChange,
  FormContentWrapper,
} from 'modules/client/common/components';
import {
  ActionProps,
  ActionType,
} from 'modules/client/common/components/FormContentWrapper/types';
import {
  LockAndUnlockReadOnlyProps,
  useLockAndUnlockReadOnly,
} from 'modules/client/common/hooks';

import { useFormContext } from '../../../forms/legalClientFirstHeadForm';
import {
  LegalClientFirstHeadSections,
  LegalClientFirstHeadSectionsProps,
} from '../../LegalClientFirstHeadSections';

export interface FormContentProps
  extends LockAndUnlockReadOnlyProps,
    LegalClientFirstHeadSectionsProps {
  submitLoading?: boolean;
  changeEmailLoading?: boolean;
  deleteLoading?: boolean;
  archiveLoading?: boolean;
  dearchiveLoading?: boolean;
  changeEmailError?: ServerError;
  resendVerificationEmailTime?: number;
  isLegalClientArchived?: boolean;
  lockClientIfPossible: () => Promise<boolean>;
  onShowVersionHistory?: () => void;
  onSendVerificationEmail?: () => void;
  onChangeEmail?: (email: string, reason: string) => Promise<boolean>;
  onDelete?: (reason: string) => void;
  onArchive?: (reason: string) => Promise<boolean>;
  onDearchive?: (reason: string) => Promise<boolean>;
}

export const FormContent: FC<FormContentProps> = ({
  submitLoading,
  resendVerificationEmailTime,
  changeEmailLoading,
  changeEmailError,
  deleteLoading,
  archiveLoading,
  dearchiveLoading,
  isLegalClientArchived,
  lockClientIfPossible,
  unlockClient,
  onShowVersionHistory,
  onSendVerificationEmail,
  onChangeEmail,
  onDelete,
  onArchive,
  onDearchive,
  ...sectionsProps
}) => {
  const { t } = useTranslation();
  const { resetForm, values, dirty, setReadOnly, readOnly } = useFormContext();

  const fullName = values ? parseFullName(values.personalData) : '';

  const { onDisableReadOnly, onEnableReadOnly } = useLockAndUnlockReadOnly(
    setReadOnly,
    { unlockClient, lockClientIfPossible }
  );

  const { open: openChangeEmailDialog, ...changeEmailDialogProps } =
    useDialog();

  const { open: openDeleteDialog, ...deleteDialogProps } = useDialog();
  const { open: openDialogReason, ...dialogReasonProps } = useDialog();

  const handleChangeEmail = async (email: string, reason: string) => {
    if (await onChangeEmail?.(email, reason)) {
      changeEmailDialogProps.onClose();
    }
  };

  const actionRef = useRef<ActionType>();

  const onActionArchive = () => {
    actionRef.current = ActionType.archive;
    openDialogReason();
  };

  const onActionDearchive = () => {
    actionRef.current = ActionType.dearchive;
    openDialogReason();
  };

  const actionMenuProps: ActionProps = {
    showEditAction: !onDearchive,
    onDelete: onDelete && !isLegalClientArchived ? openDeleteDialog : undefined,
    onChangeEmail:
      onChangeEmail && !onDearchive ? openChangeEmailDialog : undefined,
    onArchive:
      onArchive && !isLegalClientArchived ? onActionArchive : undefined,
    onDearchive:
      onDearchive && !isLegalClientArchived ? onActionDearchive : undefined,
  };

  const onSaveReason = async (reason: string) => {
    if (actionRef.current === ActionType.archive) {
      if (await onArchive?.(reason)) {
        dialogReasonProps.onClose();
      }
    } else if (actionRef.current === ActionType.dearchive) {
      if (await onDearchive?.(reason)) {
        dialogReasonProps.onClose();
      }
    }
  };

  useFormErrorFocus();

  return (
    <>
      <FormContentWrapper
        actionMenuProps={actionMenuProps}
        content={<LegalClientFirstHeadSections {...sectionsProps} />}
        dirty={dirty}
        fullName={fullName}
        headerRightContent={
          onSendVerificationEmail && readOnly && !onDearchive ? (
            <SendCodeTimer
              buttonLabel={t('common.resendEmail')}
              buttonMode="text"
              seconds={resendVerificationEmailTime}
              onClickSend={onSendVerificationEmail}
            />
          ) : undefined
        }
        resetForm={resetForm}
        shownTabBar={false}
        submitLoading={submitLoading}
        onEdit={onDisableReadOnly}
        onEnableReadOnly={onEnableReadOnly}
        onShowVersionHistory={onShowVersionHistory}
      />
      {onDelete && (
        <DialogDeleteAndReasonChange
          {...deleteDialogProps}
          deleteLoading={deleteLoading}
          onDelete={onDelete}
        />
      )}

      {onChangeEmail && (
        <DialogChangeEmail
          {...changeEmailDialogProps}
          changeEmailError={changeEmailError}
          submitLoading={changeEmailLoading}
          onSubmit={(v) => handleChangeEmail(v.email, v.reason)}
        />
      )}

      <DialogReasonChange
        submitLoading={archiveLoading ?? dearchiveLoading}
        onSave={onSaveReason}
        {...dialogReasonProps}
      />
    </>
  );
};
