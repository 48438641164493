import { FC } from 'react';

import { ServerError } from 'api/types/error';
import { SendCodeTimer } from 'components';
import { parseFullName } from 'helpers';
import { useFormErrorFocus } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { useDialog } from 'libs/ui/Dialog/useDialog';
import {
  DialogChangeEmail,
  DialogDeleteAndReasonChange,
  FormContentWrapper,
} from 'modules/client/common/components';
import {
  LockAndUnlockReadOnlyProps,
  useLockAndUnlockReadOnly,
} from 'modules/client/common/hooks';

import { useFormContext } from '../../../forms/legalClientBeneficiaryForm';
import {
  LegalClientBeneficiarySections,
  LegalClientBeneficiarySectionsProps,
} from '../../LegalClientBeneficiarySections';

export interface FormContentProps
  extends LockAndUnlockReadOnlyProps,
    LegalClientBeneficiarySectionsProps {
  submitLoading?: boolean;
  deleteLoading?: boolean;
  changeEmailLoading?: boolean;
  changeEmailError?: ServerError;
  resendVerificationEmailTime?: number;
  onShowVersionHistory?: () => void;
  onDelete?: (reason: string) => void;
  onSendVerificationEmail?: () => void;
  onChangeEmail?: (email: string, reason: string) => Promise<boolean>;
}

export const FormContent: FC<FormContentProps> = ({
  submitLoading,
  deleteLoading,
  changeEmailLoading,
  resendVerificationEmailTime,
  changeEmailError,
  unlockClient,
  lockClientIfPossible,
  onShowVersionHistory,
  onDelete,
  onSendVerificationEmail,
  onChangeEmail,
  ...sectionProps
}) => {
  const { t } = useTranslation();
  const { resetForm, values, dirty, setReadOnly, readOnly } = useFormContext();

  const fullName = values ? parseFullName(values.personalData) : '';

  const { onDisableReadOnly, onEnableReadOnly } = useLockAndUnlockReadOnly(
    setReadOnly,
    { unlockClient, lockClientIfPossible }
  );

  const { open: openDeleteDialog, ...deleteDialogProps } = useDialog();

  const { open: openChangeEmailDialog, ...changeEmailDialogProps } =
    useDialog();

  const handleChangeEmail = async (email: string, reason: string) => {
    if (await onChangeEmail?.(email, reason)) {
      changeEmailDialogProps.onClose();
    }
  };

  useFormErrorFocus();

  return (
    <>
      <FormContentWrapper
        actionMenuProps={{
          onDelete: onDelete ? openDeleteDialog : undefined,
          onChangeEmail: onChangeEmail ? openChangeEmailDialog : undefined,
        }}
        content={<LegalClientBeneficiarySections {...sectionProps} />}
        dirty={dirty}
        fullName={fullName}
        headerRightContent={
          onSendVerificationEmail && readOnly ? (
            <SendCodeTimer
              buttonLabel={t('common.resendEmail')}
              buttonMode="text"
              seconds={resendVerificationEmailTime}
              onClickSend={onSendVerificationEmail}
            />
          ) : undefined
        }
        resetForm={resetForm}
        shownTabBar={false}
        submitLoading={submitLoading}
        onEdit={onDisableReadOnly}
        onEnableReadOnly={onEnableReadOnly}
        onShowVersionHistory={onShowVersionHistory}
      />
      {onDelete && (
        <DialogDeleteAndReasonChange
          {...deleteDialogProps}
          deleteLoading={deleteLoading}
          onDelete={onDelete}
        />
      )}

      {onChangeEmail && (
        <DialogChangeEmail
          {...changeEmailDialogProps}
          changeEmailError={changeEmailError}
          submitLoading={changeEmailLoading}
          onSubmit={(v) => handleChangeEmail(v.email, v.reason)}
        />
      )}
    </>
  );
};
