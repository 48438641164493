import { useCallback, useMemo } from 'react';

import {
  useAvailableClientStatusesLazy,
  useSaveClientOnboardingStatus,
} from 'api/requests';
import { useSaveClientAccountStatus } from 'api/requests/client';
import {
  ClientAccountStatusType,
  ClientEntity,
  ClientOnboardingStatusType,
} from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';

import { filterVisibleOnboardingStatuses } from '../../common/helpers/filterVisibleOnboardingStatuses';

export const useClientStatus = (client: ClientEntity | null) => {
  const clientId = client?.id;
  const {
    availableStatuses,
    loading: fetchStatusesLoading,
    getAvailableClientStatuses,
  } = useAvailableClientStatusesLazy();

  const {
    loading: saveClientOnboardingStatusLoading,
    saveClientOnboardingStatus,
    error: saveClientOnboardingStatusError,
  } = useSaveClientOnboardingStatus();

  const {
    error: saveClientAccountStatusError,
    loading: saveClientAccountStatusLoading,
    saveClientAccountStatus,
  } = useSaveClientAccountStatus();

  const notify = useNotify();

  const { t } = useTranslation();

  const visibleOnboardingStatuses = useMemo(
    () => filterVisibleOnboardingStatuses(client?.onboarding?.statuses),
    [client?.onboarding?.statuses]
  );

  const statuses = useMemo(
    () => ({
      onboarding: availableStatuses?.onboarding ?? [],
      account: availableStatuses?.account ?? [],
    }),
    [availableStatuses?.account, availableStatuses?.onboarding]
  );

  const fetchAvailableStatuses = useCallback(() => {
    if (clientId) getAvailableClientStatuses(clientId);
  }, [getAvailableClientStatuses, clientId]);

  const onChangeOnboardingStatus = async (
    value: ClientOnboardingStatusType,
    result?: string
  ) => {
    const updatedClient = await saveClientOnboardingStatus(clientId!, {
      value,
      result,
      versionNumber: client?.versionNumber!,
    });

    if (updatedClient) {
      if (value === ClientOnboardingStatusType.AccountOpened) {
        notify.info(t('client.client.statuses.createBrightText'));
      } else {
        notify.info(t('common.successSave'));
      }
    }
  };

  const onChangeAccountStatus = async (value: ClientAccountStatusType) => {
    const updatedClient = await saveClientAccountStatus(clientId!, {
      value,
      versionNumber: client?.versionNumber!,
    });

    if (updatedClient) {
      notify.info(t('common.successSave'));
    }
  };

  useServerErrorNotify(saveClientOnboardingStatusError);
  useServerErrorNotify(saveClientAccountStatusError);

  return {
    statuses,
    loading: fetchStatusesLoading,
    submitLoading:
      saveClientOnboardingStatusLoading || saveClientAccountStatusLoading,
    fetchAvailableStatuses,
    onChangeOnboardingStatus,
    visibleOnboardingStatuses,
    onChangeAccountStatus,
  };
};
