import {
  useDeleteLegalClientFirstHead,
  useDeleteLegalClientLegalHead,
  useLegalClient,
  useLegalClientFirstHeadArchive,
  useLegalClientFirstHeadDearchive,
  useLegalClientLegalHead as useLegalClientLegalHeadApi,
  useLegalClientLegalHeadArchive,
  useLegalClientLegalHeadDearchive,
} from 'api/requests';
import { useCountries } from 'api/requests/country';
import { PermissionType } from 'api/types/entity';
import { NavigationBreadcrumbsTitles } from 'components';
import { useServerErrorNotify } from 'hooks';
import { FormSubmit } from 'libs/form/Form';
import { useTranslation } from 'libs/i18n';
import { ROUTES, useNavigate, RoutesId } from 'libs/navigation';
import { useNotify } from 'libs/notify';
import { usePermissions } from 'libs/permissions';

import { LegalClientLegalHeadFormType } from '../forms/legalClientLegalHeadForm';
import { getLegalClientLegalHeadInitialValue } from '../helpers';

import { useLegalHeadPageTitle } from './useLegalHeadPageTitle';
import { useLockLegalClientLegalHead } from './useLockLegalClientLegalHead';
import { useUpdateLegalClientLegalHead } from './useUpdateLegalClientLegalHead';

type NavigateParams = {
  legalClientId: string;
  headId: string;
};

export const useLegalClientLegalHead = () => {
  const { t } = useTranslation();

  const { params, navigate } = useNavigate<NavigateParams>();

  const notify = useNotify();

  const {
    loading,
    legalClientLegalHead,
    error,
    refetch: refetchLegalClientLegalHead,
  } = useLegalClientLegalHeadApi(params.headId!);

  const { loading: submitLoading, updateLegalClientLegalHead } =
    useUpdateLegalClientLegalHead();

  const { countries, loading: countriesLoading } = useCountries();

  const { legalClient } = useLegalClient(params.legalClientId!);

  const { lockClientIfPossible, unlockClient } = useLockLegalClientLegalHead(
    legalClient,
    legalClientLegalHead
  );

  const {
    deleteLegalClientLegalHead,
    loading: deleteLoading,
    error: deleteError,
  } = useDeleteLegalClientLegalHead();

  const { checkPermissions } = usePermissions();

  const { deleteLegalClientFirstHead, error: deleteFirstHeadError } =
    useDeleteLegalClientFirstHead();

  const {
    loading: archiveLoading,
    archive,
    error: archiveError,
  } = useLegalClientLegalHeadArchive();
  const {
    loading: dearchiveLoading,
    dearchive,
    error: dearchiveError,
  } = useLegalClientLegalHeadDearchive();

  const { archive: archiveFirstHead, error: archiveFirstHeadError } =
    useLegalClientFirstHeadArchive();
  const { dearchive: dearchiveFirstHead, error: dearchiveFirstHeadError } =
    useLegalClientFirstHeadDearchive();

  const initialValues = legalClientLegalHead
    ? getLegalClientLegalHeadInitialValue(legalClientLegalHead, t)
    : undefined;

  const breadcrumbsTitles: NavigationBreadcrumbsTitles = {
    [RoutesId.legalClientLegalHead]: legalClientLegalHead?.fullName,
    [RoutesId.legalClient]: legalClient?.fullName,
  };

  const onSubmit: FormSubmit<LegalClientLegalHeadFormType> = async (
    values: LegalClientLegalHeadFormType,
    helpers
  ) => {
    if (await updateLegalClientLegalHead(legalClientLegalHead!, values)) {
      helpers.setReadOnly(true);
    }
  };

  const onClickHead = (firstHeadId: string) => {
    if (legalClientLegalHead?.legalClientId) {
      navigate(
        ROUTES.clients.legalClients.legalClient.legalClientLegalHead
          .legalClientFirstHead.fullPath,
        {
          legalClientId: legalClientLegalHead.legalClientId,
          headId: legalClientLegalHead.id,
          firstHeadId,
        }
      );
    }
  };

  const onShowVersionHistory =
    legalClient &&
    legalClientLegalHead?.versionNumber &&
    legalClientLegalHead?.versionNumber > 1
      ? () => {
          navigate(
            ROUTES.clients.legalClients.legalClient.legalClientVersion
              .legalClientLegalHeadVersion.fullPath,
            {
              legalClientId: legalClient.id,
              headId: params.headId!,
              versionId: legalClientLegalHead.versionId!,
            }
          );
        }
      : undefined;

  const onDelete = checkPermissions([
    PermissionType.LegalClientLegalHeadDeleteOne,
  ])
    ? async (reason: string) => {
        if (
          await deleteLegalClientLegalHead({
            id: params.headId!,
            reason,
            versionNumber: legalClient?.versionNumber!,
          })
        ) {
          notify.info(t('client.legal.legalClientHead.successDeleteMessage'));
          navigate(
            ROUTES.clients.legalClients.legalClient.fullPath,
            {
              legalClientId: params.legalClientId!,
            },
            { replace: true }
          );
        }
      }
    : undefined;

  const onDeleteFirstHead = async (id: string, reason: string) => {
    if (
      await deleteLegalClientFirstHead({
        id,
        reason,
        versionNumber: legalClient?.versionNumber!,
      })
    ) {
      notify.info(t('client.legal.legalClientFirstHead.successDeleteMessage'));
      refetchLegalClientLegalHead();
    }
  };

  const onArchive = !legalClientLegalHead?.archived
    ? async (reason: string) => {
        if (
          await archive(params.headId!, {
            reason,
            versionNumber: legalClientLegalHead?.versionNumber!,
          })
        ) {
          notify.info(t('client.legal.legalClientHead.archivedMessage'));
          return true;
        }
        return false;
      }
    : undefined;

  const onDearchive = legalClientLegalHead?.archived
    ? async (reason: string) => {
        if (
          await dearchive(params.headId!, {
            reason,
            versionNumber: legalClientLegalHead?.versionNumber!,
          })
        ) {
          notify.info(t('client.legal.legalClientHead.dearchivedMessage'));
          return true;
        }
        return false;
      }
    : undefined;

  const onArchiveFirstHead = async (id: string, reason: string) => {
    if (
      await archiveFirstHead(id, {
        reason,
        versionNumber: legalClientLegalHead?.versionNumber!,
      })
    ) {
      await refetchLegalClientLegalHead();
      notify.info(t('client.legal.legalClientFirstHead.archivedMessage'));
    }
  };

  const onDearchiveFirstHead = async (id: string, reason: string) => {
    if (
      await dearchiveFirstHead(id, {
        reason,
        versionNumber: legalClientLegalHead?.versionNumber!,
      })
    ) {
      await refetchLegalClientLegalHead();
      notify.info(t('client.legal.legalClientFirstHead.dearchivedMessage'));
    }
  };

  useLegalHeadPageTitle(legalClient, legalClientLegalHead);

  useServerErrorNotify(
    deleteError ??
      deleteFirstHeadError ??
      archiveError ??
      dearchiveError ??
      archiveFirstHeadError ??
      dearchiveFirstHeadError
  );

  return {
    legalClientLegalHead,
    legalClient,
    loading: !legalClientLegalHead && loading,
    countries: countries ?? [],
    countriesLoading,
    error,
    submitLoading,
    initialValues,
    breadcrumbsTitles,
    deleteLoading,
    archiveLoading,
    dearchiveLoading,
    onDeleteFirstHead,
    onDearchiveFirstHead,
    lockClientIfPossible,
    unlockClient,
    onSubmit,
    onClickHead,
    onShowVersionHistory,
    refetchLegalClientLegalHead,
    onDelete,
    onArchive,
    onDearchive,
    onArchiveFirstHead,
  };
};
